import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DevSettingsComponent } from '../dev-settings';
import { DevStoreMonitorComponent } from '../dev-store-monitor';

@Component({
  selector: 'cca-dev-menu',
  imports: [MatTabsModule, DevSettingsComponent, DevStoreMonitorComponent],
  templateUrl: './dev-menu.component.html',
  styleUrl: './dev-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevMenuComponent {}
